import React from 'react';
import VirtualMobile from '../images/04_Virtual-Mobile-Numbers.svg';
import RoamingSms from '../images/03_Roaming-SMS.svg';
import Roaming_Voice from '../images/02_Roaming-Voice.svg';

type Props = {};
const MoreServices: React.FC<Props> = ({}) => {
  return (
    <div className="section wf-section">
    <div className="container">
      <div className="align-center">
        <h3 className="display-3">How will you use MTX Connect Business?</h3>
      </div>
      <div className="margin-30px">
        <div className="w-layout-grid services-grid">
          <div className="service-link"><img loading="lazy" src={Roaming_Voice} alt="" className="image-rounded" />
            <h4 className="display-6">The VoIP phone service</h4>
            <p>Carrier grade low-cost corporate calling solution for most countries and regions of the world.</p>
            <a href="https://b2b.mtxc.eu/coming-soon" className='link-block w-inline-block'>
              <p className="paragraph blue">Coming this fall...</p>
            </a>
          </div>
          <div className="service-link"><img loading="lazy" src={RoamingSms} alt="" className="image-rounded" />
            <h4 className="display-6">Global SMS Messaging</h4>
            <p>SMS messaging around the world on a platform powered by more than 320 carrier-to-carrier connections.</p>
            <a href="https://b2b.mtxc.eu/coming-soon" className='link-block w-inline-block'>
              <p className="paragraph blue">Coming this fall...</p>
            </a>
          </div>
          <div className="service-link"><img loading="lazy" src={VirtualMobile} alt=""
              className="image-rounded" />
            <h4 className="display-6">Mobile numbers</h4>
            <p>Choose from 100% whitelisted Luxembourg and UK mobile numbers with guaranteed call and SMS delivery.</p>
            <a href="https://b2b.mtxc.eu/coming-soon" className='link-block w-inline-block'>
              <p className="paragraph blue">Coming in 2022...</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default MoreServices;
