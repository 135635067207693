import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import smoothscroll from 'smoothscroll-polyfill';
import { toggleModal } from './reducers/ModalsReducer';
import MetaTags from './components/MetaTags';
import MoreServices from './components/MoreServices';
import MapBig from './images/06.svg';
import Map from './images/01-1.svg';
import License from './images/license.svg';
import Global from './images/global.svg';
import Goal from './images/goal.svg';
import MapBlue from './images/04-2.svg';
import AppMeta from './components/AppMeta';
import { AppState } from './store';
import RequestModal from './components/RequetModal';
import './styles/normalize.scss';
import './styles/webflow.scss';
import './styles/mtxc.webflow.scss';
import './styles/Onboarding.scss';
import Header from './components/Header';
import Footer from './components/Footer';

smoothscroll.polyfill();

function App() {
  const dispatch = useDispatch();
  const [showScrollTop, setshowScrollTop] = useState(false);
  const requestModal = useSelector((state: AppState) => state.modals.requestModal);

  useEffect(() => {
    $crisp.push(["set", "session:segments", [["b2b_portal"]]]);
    $crisp.push(["do", "chat:hide"]);
    $crisp.push(["on", "chat:closed", () => {
      $crisp.push(["do", "chat:hide"]);
    }]);
    window.scrollTo(0, 0);
    document.body.classList.add("onboarding");
    document.addEventListener('scroll', () => setshowScrollTop(window.pageYOffset >= 150));

    return () => {
      document.removeEventListener('scroll', () => setshowScrollTop(false));
      document.body.classList.remove("onboarding");
    }
  }, []);

  const scrollTop = useCallback(() => {
    const el = document.getElementById('Top');
    if (el) {
      let top = el.getBoundingClientRect().top + window.pageYOffset
      window.scrollTo({top, behavior: 'smooth'});
    }
  }, []);

  const contactSales = useCallback(() => {
    dispatch(toggleModal('requestModal', { from: 'Private LTE' }));
  }, []);

  return (<>
    <AppMeta />
    <MetaTags title="Private LTE" />
    {requestModal.active && <RequestModal title={requestModal.data?.from} />}
    <Header />
    <div id="Top" className="section _180px-top black wf-section">
      <div className="container w-container">
        <div className="align-center">
          <h1 className="display-1 white">MTX Connect integration services<br /></h1>
        </div>
        <div className="margin-50px">
          <div className="w-layout-grid case-inside-grid">
            <div className="case-1 grey">
              <div>
                <h2 className="display-2 white">Private LTE for large industrial applications.</h2>
                <div className="meta-tag-flex">
                  <p className="paragraph white">Work everywhere, without compromising security and costs. Bring your content, tools and collaborators together — only with MTX Connect Business.</p>
                </div>
              </div>
              <div className="margin-50px">
                <a data-w-id="e9511386-5ea4-b69b-b039-45bd13cc2cc9" onClick={contactSales} className="button whiter _200px w-button">Contact sales</a>
              </div>
            </div>
            <div className="case-left enterprise"></div>
          </div>
        </div>
      </div>
    </div>
    <div className="section black wf-section">
      <div className="container w-container">
        <div className="components-grid-style">
          <div className="sticky-modules-wrapper">
            <div className="sticky-module">
              <h2 className="display-2 no-margin white">Keep your teams productive and your comms safe.</h2>
              <div className="sticky-text">
                <div className="margin-40px">
                  <p className="paragraph white">Private mobile network. <span className="dimmed">Network access and use policies are completely under your control.</span><br /></p>
                </div>
              </div>
            </div>
            <div className="sticky-module">
              <div className="sticky-text">
                <p className="paragraph white">Innovation. <span className="dimmed">Smart devices based on virtualization and artificial intelligence.</span></p>
              </div>
            </div>
            <div className="sticky-module">
              <div className="sticky-text">
                <p className="paragraph white">Manageability and scalability.<span className="dimmed"> Configure the network to meet your needs of certain technical processes and development projects.</span></p>
              </div>
            </div>
            <div className="sticky-module">
              <div className="sticky-text">
                <p className="paragraph white">Security. <span className="dimmed paragraph">Ensure comprehensive security of mobile traffic while allowing voice and data connectivity over public networks.</span><br /></p>
              </div>
            </div>
            <div className="sticky-module short">
              <div className="sticky-text last">
                <p className="paragraph white">Cost efficiency. <span className="dimmed">Eliminate external communication expenses, and don&#x27;t depent on external resources to solve the problem of mobility development.</span><br /></p>
                <div className="margin-50px">
                  <a data-w-id="35373966-8c47-b55b-f40d-5b299320ccba" onClick={contactSales} className="button whiter _200px w-button">Contact sales</a>
                </div>
              </div>
            </div>
          </div>
          <div id="w-node-ff342cf5-264d-a83f-07b6-095137d0d392-e84d86e7" className="sticky-image"><img src={Map} loading="lazy" alt="Keep your teams productive and your comms safe." className="feature-image stretch with-bg" /></div>
        </div>
      </div>
    </div>
    <div className="section grey wf-section">
      <div className="container w-container">
        <div className="_70--80--100">
          <h2 className="display-1">No one does mobile connectivity like MTX Connect.</h2>
        </div>
        <div className="margin-80px"><img src={MapBig} loading="lazy" alt="No one does roaming like MTX Connect." /></div>
      </div>
    </div>
    <div className="hero black">
      <div className="container">
        <div>
          <div className="components-grid-style reverse">
            <div id="w-node-_8f0a9faa-6835-c3f3-eab4-50febffc4dc2-e84d86e7" className="sticky-image"><img src={MapBlue} loading="lazy" alt="Do more with MTX Connect roaming services." className="feature-image stretch with-bg" /></div>
            <div className="sticky-modules-wrapper">
              <div className="sticky-module">
                <h2 className="display-2 no-margin white">Do more with MTX Connect roaming services.</h2>
                <div className="margin-30px">
                  <p className="subhead white">Everything a major industrial company with several geographical divisions needs for mobile communications.<br /></p>
                </div>
              </div>
              <div className="sticky-module">
                <div className="sticky-text">
                  <p className="paragraph white">You are in control. <span className="dimmed">Enjoy the independence of your own corporate communications structure, transparent switching, numbering plans, and network usage policies.</span></p>
                </div>
              </div>
              <div className="sticky-module">
                <div className="sticky-text">
                  <p className="paragraph white">It&#x27;s 100% yours. <span className="dimmed">Protect your corporate voice and data traffic within the Private LTE segment.</span></p>
                </div>
              </div>
              <div className="sticky-module">
                <div className="sticky-text">
                  <p className="paragraph white">It&#x27;s you who decides. <span className="dimmed">Be independent and equidistant from radio segment equipment suppliers by choosing your own base station operator and radio spectrum sponsor.</span></p>
                </div>
              </div>
              <div className="sticky-module">
                <div className="sticky-text">
                  <p className="paragraph white">No more cost concerns. <span className="dimmed">Independently charge internal subscribers for the needs of cost allocation between departments, transparently accrue internal costs.</span><br /></p>
                </div>
              </div>
              <div className="sticky-module">
                <div className="sticky-text">
                  <p className="paragraph white">Roaming? Easy. <span className="dimmed">Make your own decisions and manage access to mobile networks outside the Private LTE segment, within national or/and international roaming.</span><br /></p>
                </div>
              </div>
              <div className="sticky-module short">
                <div className="sticky-text last">
                  <p className="paragraph white">We grow together with you. <span className="dimmed">Build your own expertise in the implementation of Internet of Things (IoT) technologies,  production processes automation, smart technologies and artificial intelligence (AI) tools implementation.</span><br /></p>
                  <div className="margin-50px">
                    <a data-w-id="3e497234-686b-6f1c-d5e7-b98407cebdf3" onClick={contactSales} className="button whiter _200px w-button">Contact sales</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="section black wf-section">
      <div className="container w-container">
        <div className="align-center">
          <h2 className="display-1 _70">More features to geek out.</h2>
        </div>
        <div className="margin-80px">
          <div className="features _80--100">
            <div className="w-layout-grid more-features-grid shift-left">
              <div className="feature-card">
                <div className="display-2 white no-margin">6 local breakouts</div>
                <div className="margin-15px">
                  <p className="paragraph white dimmed">to solve latency issues</p>
                </div>
              </div>
              <div className="feature-card">
                <div className="display-4">Customised IP routing and APN settings<br /></div>
                <div className="margin-15px">
                  <p className="paragraph white dimmed">for network flexibility &amp; enhanced management possibilities</p>
                </div>
              </div>
            </div>
            <div className="w-layout-grid more-features-grid shift-right">
              <div className="feature-card">
                <p className="paragraph white dimmed">Up to</p>
                <div className="display-4">99.99% guaranteed uptime</div>
                <div className="margin-15px">
                  <p className="paragraph white dimmed"> for all MTC Connect Business components</p>
                </div>
              </div>
              <div className="feature-card">
                <div className="display-2 white no-margin">Virtualized core network</div>
                <div className="margin-15px">
                  <p className="paragraph white dimmed">for isolation and core slicing</p>
                </div>
              </div>
            </div>
            <div className="w-layout-grid more-features-grid shift-left">
              <div className="feature-card">
                <div className="display-2 white no-margin">The new eSIM technology</div>
                <div className="margin-15px">
                  <p className="paragraph white dimmed">for reliability and on–net provisioning</p>
                </div>
              </div>
              <div className="feature-card">
                <p className="paragraph white dimmed">More then</p>
                <div className="display-4">200 direct roaming agreements</div>
                <div className="margin-15px">
                  <p className="paragraph white dimmed">for connectivity and roaming cost optimization<br /></p>
                </div>
              </div>
            </div>
            <div className="w-layout-grid more-features-grid shift-right">
              <div className="feature-card">
                <div className="display-4">Signaling and location updates</div>
                <div className="margin-15px">
                  <p className="paragraph white dimmed">for monitoring &amp; reporting</p>
                </div>
              </div>
              <div className="feature-card">
                <div className="display-2 white no-margin">DPI and content filtering</div>
                <div className="margin-15px">
                  <p className="paragraph white dimmed">for proper Internet use</p>
                </div>
              </div>
            </div>
            <div className="w-layout-grid more-features-grid shift-left">
              <div id="w-node-c34cf18a-4c1b-03f3-1663-c7da3b1dcc92-106a2474" className="feature-card">
                <div className="display-2 white no-margin">Core Network API</div>
                <div className="margin-15px">
                  <p className="paragraph white dimmed">for third party apps integrations and system management</p>
                </div>
              </div>
              <div id="w-node-_7a3ed617-5e07-e316-6877-9ebb0dd79bac-106a2474" className="feature-card">
                <div className="display-4">Direct interconnect and Internet bypass</div>
                <div className="margin-15px">
                  <p className="paragraph white dimmed">for network security and reliability</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="margin-60px">
          <div className="more-featurs-grid _80--100">
            <div className="more-features"><img src={License} loading="lazy" alt="License from Luxembourg Regulator" className="more-features-icon" />
              <p className="big-paragraph white no-margin">License from Luxembourg Regulator<br /></p>
            </div>
            <div className="more-features"><img src={Global} loading="lazy" alt="Global operations" className="more-features-icon" />
              <p className="big-paragraph white no-margin">Global operations<br /></p>
            </div>
            <div id="w-node-b1a9245f-1431-d399-1410-9fd7499218fa-e84d86e7" className="more-features"><img src={Goal} loading="lazy" alt="Several projects successfully deployed" className="more-features-icon" />
              <p className="big-paragraph white no-margin">Several projects successfully deployed<br /></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MoreServices />
    <a onClick={scrollTop} className={`go-top w-inline-block ${showScrollTop ? 'active' : ''}`}>
      <img src="https://uploads-ssl.webflow.com/5ffd76b1a1519877c62735a1/5ffda90db6bf3fea11a6c527_icons8-up-144.png" width="20" alt="" className="top-arrow" />
    </a>
    <Footer />
  </>);
}

export default App;
